import './App.css';

function App() {
  return (
    <div className="App container">
      <header>
        <img src="img/kss-logo.png" class="logo" alt="KSS Solutions" height="100px" />
        <h1 class="page-title">KSS Solutions</h1>
      </header>

      <nav>
        <section class="box-demo">
          <div class="box-flex">Home</div>
          <div class="box-flex">About</div>
          <div class="box-flex">Projects</div>
          <div class="box-flex">Team</div>
          <div class="box-flex">Contact</div>

        </section>
      </nav>

      <hr class="header-line" />

      <main>
        <section class="mission-statement">
          <div class="mission-statement-pic">
            <img src="img/noun-gear-6415198.svg" class="gear-icon" alt="https://thenounproject.com/icon/gear-6415198/" />
          </div>
          <div class="mission-statement-copy">
            <h2>IT Solutions for <span className='highlight'>Independant Content Creators</span></h2>
            <p>KSS Solutions is the personal IT constultancy of Kevin Stefanowicz. If you want to build something cool on the
              internet, I can help make it happen.</p>
          </div>
        </section>



        <section class="help-form">
          Ready to get started? Let me know:
          <form action="">
            <textarea name="Inquiry" id="" cols="50" rows="4" placeholder="How can I help?" required></textarea>
            <br />
            <button id="submitButton" type="submit">Submit</button>
          </form>
        </section>
      </main>



      <script src="https://platform.linkedin.com/badges/js/profile.js" async defer type="text/javascript"></script>
    </div>
  );
}

export default App;
